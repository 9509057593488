import React, { ChangeEvent, FormEvent, useState, useContext } from "react";
import { navigate } from "gatsby";
import { AxiosResponse } from "axios";
import clsx from "clsx";

import { post } from "~/helpers/api";

import { MainContext } from "~/context/main";

import Button from "~/components/button";
import { InputText } from "~/components/Inputs";
import { generateUrl } from "~/helpers/urlHelpers";
import { getClassAsStudent } from "~/helpers/lessonHelpers";


interface JoinClassFormProps {
  background?: "light" | "dark";
}

interface StudentClass {
  level: "HS" | "MS";
}

export const JoinClassForm = ({ background="light" }: JoinClassFormProps): JSX.Element => {
  const { setClassCode } = useContext(MainContext);

  const [magicKey, setMagicKey] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setMagicKey(e.target.value.split(/\s/g).join(""));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    setLoading(true);

    try {
      const studentClass: AxiosResponse<any> | undefined = await getClassAsStudent(magicKey);
      post("/createStudentSession", { code: magicKey });

      const studentClassTypes =
        ((studentClass as unknown as StudentClass).level === "MS" && "middle-school") ||
        ((studentClass as unknown as StudentClass).level === "HS" && "high-school");

      localStorage.setItem("class_code", magicKey);
      setClassCode(magicKey);
      setLoading(false);
      return navigate(generateUrl(studentClassTypes, 1, 1));
    } catch (e) {
      setLoading(false);
      console.log(e);
      return setError("The class code is invalid");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label
          className={clsx(
            "mb-4",
            {
              "text-sm text-gray-500": background === "light",
              "text-lg font-medium text-white": background === "dark"
            }
          )}
          htmlFor="joinClass"
      >
          Class code
        </label>
          <InputText
            className={clsx(
              "w-full",
              {
                "bg-gray-100": background === "light",
                "bg-white": background === "dark"
              }
            )}
            id="joinClass"
            onChange={handleChange}
            placeholder="code"
          />
        {error && <p className="text-xs leading-8 mb-3 text-lightError">{error}</p>}
      </div>
      <Button
        action="primary"
        color={background === "light" ? "purple" : "white"}
        className="w-full mt-6"
        loading={loading}
        title="Join class"
        type="submit"
      >
        Join class
      </Button>
    </form>
  );
};

export default JoinClassForm;
