export const generateUrl = (
  type: string | boolean,
  lesson: string | number,
  step: string | number,
  isTeacher?: boolean | undefined
): string => {
  return `/${type}/lesson-${lesson}/step-${step}${isTeacher ? '/notes' : ''}`;
};

export default generateUrl;
