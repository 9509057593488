import React, { useState } from "react";
import clsx from "clsx";

import { formatClassList } from "~/utils";


interface RadiosTypes {
  name: string;
  items: Array<{ id: string; value: string; label: string }>;
}

interface InputProps {
  label?: string;
  defaultChecked?: string;
  radios: RadiosTypes;
  onChange: (event: { target: HTMLInputElement }) => void;
  color: string
}

const INPUT = `
  after:absolute
  after:bg-white
  after:border
  after:border-gray-400
  after:border-solid
  after:h-5
  after:inline-block
  after:rounded-full
  after:visible
  after:w-5
  before:absolute
  before:border
  before:border-gray-400
  before:border-solid
  before:h-5
  before:inline-block
  before:rounded-full
  before:visible
  before:w-5
  checked:after:border-2
  checked:before:h-3
  checked:before:ml-1
  checked:before:w-3
  checked:before:z-10
  cursor-pointer
  flex
  items-center
  justify-start
  mr-4
  outline-none
`

export const LabeledRadio: React.FC<InputProps> = ({
  label,
  defaultChecked,
  radios,
  onChange,
  color
}) => {
  const [focused, setFocused] = useState<number>(-1);
  const isMs = color === 'ms'

  return (
    <div className="max-w-sm flex flex-col w-full justify-start items-start text-gray-600 text-sm">
      {label}
      <div className="grid grid-cols-2 mt-2">
        {radios.items?.map((radioInput, index: number) => (
          <div className={
            clsx("cursor-pointer p-2", {
              "ring-4 rounded": focused === index,
              "ring-purple-300": focused === index && isMs,
              "ring-indigo-300": focused === index && !isMs
            })
          }>
            <label htmlFor={radioInput.id} key={radioInput.id} className="cursor-pointer flex justify-start items-center">
              <input
                defaultChecked={defaultChecked === radioInput.value}
                type="radio"
                id={radioInput.id}
                name={radios.name}
                value={radioInput.value}
                onChange={onChange}
                onFocus={() => setFocused(index)}
                onBlur={() => setFocused(-1)}
                className={clsx(
                  formatClassList(INPUT),
                  {
                   'checked:after:border-purple-700 checked:before:bg-purple-700 checked:before:border-purple-700': isMs,
                   'checked:after:border-indigo-700 checked:before:bg-indigo-700 checked:before:border-indigo-700': !isMs
                })}
              />
              {radioInput.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

export default LabeledRadio;
