import React, {
  ChangeEvent,
  FormEvent,
  useState,
  useEffect
} from "react";
import { navigate } from "gatsby";

import { post } from "~/helpers/api";

import Button from "~/components/button";
import formValidation from "~/helpers/formValidation";
import { Form } from "./form";
import { InputField } from "~/components/Inputs";


export const NewPasswordForm = (): JSX.Element => {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [queryString, setQueryString] = useState("");

  const validationSchema = formValidation("password");
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get("token");

  useEffect(() => {
    if (window) {
      setQueryString(window.location.search);
    }
  }, []);

  useEffect(() => {
    if (!validationSchema(password)) return setError("");
  }, [password]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const validationError = validationSchema(password);
    if (validationError) return setError(validationError)

    setLoading(true);

    try {
      await post("/new-password", ({ token, password }));
      setLoading(false)
      navigate("/sign-in");
    } catch(error) {
      setLoading(false)
      setError("Token invalid or expired");
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setPassword(value);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <InputField
        onChange={handleChange}
        label="New Password"
        value={password}
        name="password"
        type="password"
        error={error}
      />
      <div className="text-tn text-blue max-w-sm mt-3">
        Your password must have at least 8 characters, minimum one lowercase and
        uppercase letter, number and special character
      </div>
      <Button
        action="primary"
        className="mt-5"
        color="purple"
        loading={loading}
        onClick={handleSubmit}
        title="Reset password"
        type="submit"
        style={{ minWidth: "250px" }}
      >
        Reset password
      </Button>
    </Form>
  );
};

export default NewPasswordForm;
