import axios from 'axios'

const isFunction = (func: any) => {
  return typeof func === "function"
}

const sendFormDataToPymail = (args: { cancel_token: any; data: any; failCallback?: any; successCallback?: any }) => {
    const {
      cancel_token,
      data,
      failCallback,
      successCallback
    } = args

    axios({
      headers: {
        'content-type': 'application/json',
        "Access-Control-Allow-Origin": true
      },
      url: 'https://pymail.bscs.org/form',
      method: 'post',
      data: data,
      cancelToken: cancel_token.token
    })
      .then(response => {
        if (response.status === 200) {
          successCallback()
        }
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          console.log(`Request canceled: ${error}`)
        } else {
          console.log(error)

          if (isFunction(failCallback)) {
            failCallback()
          }
        }
      })
}

export default sendFormDataToPymail
