import React from 'react';
import clsx from 'clsx'

import useLesson from '~/hooks/useLesson';

interface HeadingProps {
  level: number;
  underline?: boolean;
  color?: string;
  isBigger?: boolean;
}


export const Heading: React.FC<HeadingProps> = ({
  level,
  underline = false,
  children,
  isBigger,
  ...props
}) => {
  const { color } = useLesson();
  const isMs = props.color ? props.color === 'ms' : color === 'ms'
  switch (level) {
    case 1:
      return (
        <>
          <h1
            color={props?.color || color}
            className={clsx("text-darkBlue text-2xl font-bold leading-10 after:block", {
              "after:border-2 after:rounded after:h-1 after:w-8": underline,
              "after:border-purple-600": isMs && underline,
              "after:border-indigo-600": !isMs && underline
              })}
            {...props}
          >
            {children}
          </h1>
        </>
      );
    case 2:
      return (
        <>
          <h2
            className={clsx("text-darkBlue text-xl font-bold leading-10 after:block", {
               'text-2xl': isBigger,
                "after:border-2 after:rounded after:h-1 after:w-8": underline,
                "after:border-purple-600": isMs && underline,
                "after:border-indigo-600": !isMs && underline
            })}
            {...props}
          >
            {children}
          </h2>
        </>
      );
    default:
      return (
         <h2
            className={clsx("text-darkBlue text-xl font-bold leading-10 after:block", {
              "text-2xl": isBigger,
              "after:border-2 after:rounded after:h-1 after:w-8": underline,
              "after:border-purple-600": isMs && underline,
              "after:border-indigo-600": !isMs && underline
            })}
         {...props}
       >
         {children}
       </h2>
      );
  }
};

export default Heading;
