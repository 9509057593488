exports.LOGIN = `
  mutation loginUser($email: String!, $password: String!) {
    loginUser(input: { email: $email, password: $password })
  }
`;

exports.LOGOUT = `
  mutation logout {
    logout
  }
`;

exports.REGISTER = `
  mutation createUser(
    $email: String!
    $password: String!
    $firstName: String
    $followUpForm: Boolean!
    $lastName: String
    $role: String
    $schoolDistrict: String
    $schoolName: String
    $schoolEmail: String
    $country: String
    $state: String
    $active: Boolean
  ) {
    createUser(
      input: {
        email: $email
        password: $password
        firstName: $firstName
        followUpForm: $followUpForm
        lastName: $lastName
        role: $role
        schoolDistrict: $schoolDistrict
        schoolName: $schoolName
        schoolEmail: $schoolEmail
        country: $country
        state: $state
        active: $active
      }
    ) {
      email
      firstName
      followUpForm
      lastName
      role
      schoolDistrict
      schoolName
      schoolEmail
      country
      state
      active
    }
  }
`;

exports.UPDATE_USER = `
  mutation updateUser(
    $email: String
    $password: String
    $firstName: String
    $lastName: String
    $role: String
    $schoolDistrict: String
    $schoolName: String
    $schoolEmail: String
    $country: String
    $state: String
  ) {
    updateUser(
      input: {
        email: $email
        password: $password
        firstName: $firstName
        lastName: $lastName
        role: $role
        schoolDistrict: $schoolDistrict
        schoolName: $schoolName
        schoolEmail: $schoolEmail
        country: $country
        state: $state
      }
    ) {
        email
        firstName
        lastName
        role
        schoolDistrict
        schoolName
        schoolEmail
        country
        state
    }
  }
`;

exports.UPDATE_PASSWORD = `
  mutation updatePassword(
    $id: ID!
    $password: String!
  ) {
    updatePassword(input: {
      id: $id
      password: $password
    }) {
      email
    }
  }
`

exports.DELETE_ACCOUNT = `
  mutation deleteUser(
    $id: ID!
  ) {
    deleteUser(input: {
      id: $id
    }) {
      _id
    }
  }
`;

exports.NEW_CLASS = `
mutation createClass(
  $name: String!
  $code: String!
  $level: SchoolLevel!
) {
  createClass(
    data: {
      name: $name
      code: $code
      level: $level
    }
  ) {
    name
    code
    level
  }
}
`;

exports.DELETE_CLASS = `
  mutation ($id: ID!) {
    deleteClass(id: $id) {
      _id
    }
  }
`;

exports.UPDATE_CLASS_NAME = `
  mutation updateClassName(
    $id: ID!
    $name: String!
  ) {
    updateClassName(input: { id: $id, name: $name }) {
      name
    }
  }
`;

exports.CREATE_POLL_RESPONSE = `
  mutation createPollResponse($code: String! $level: SchoolLevel! $response: Response!) {
    createPollResponse(
      input: {
        code: $code
        level: $level
        response: $response
      }
    ) {
      _id
    }
  }
`;

exports.CREATE_STUDENT_SESSION = `
  mutation createStudentSession($code: String!) {
    createStudentSession(
      input: {
        code: $code
      }
    ) {
      _id
    }
  }
`;

exports.CREATE_TOKEN = `
  mutation createToken($token: String! $email: String! $expirationDate: Float!) {
    createToken(data:{
      token: $token
      email: $email
      expirationDate: $expirationDate
    }) {
      token
    }
  }
`;

exports.UPDATE_TOKEN = `
  mutation updateToken($id: ID! $expirationDate: Float!) {
    updateToken(id: $id, data: {
      expirationDate: $expirationDate
    }) {
      token
    }
  }
`

exports.FORGOT_PASSWORD = `
  mutation forgotPassword($id: String! $password: String!) {
    forgotPassword(input: {
      id: $id
      password: $password
    }) {
      email
    }
  }
`;
