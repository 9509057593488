const axios = require('axios');

exports.sendQuery = async (query, key, variables = {}) => {
  console.log("query beginning")
  try {
    const result = await axios({
      method: 'POST',
      url: 'https://graphql.fauna.com/graphql',
      headers: {
        'content-type': 'application/json',
        // Authorization: `Bearer ${key ? key : process.env.FAUNA_DB_SECRET}`,
        Authorization: `Bearer ${key ? key : 'fnAEPsMGFSACC9F8MVfRfUgW3tF1N6Fm66nhtg0M'}`,

      },
      data: {
        variables,
        query,
      },
    });
    console.log("query success")
    return result.data;
  } catch (err) {
    console.log("query error")
    return new Error(err);
  }
};