import React, {
  ChangeEvent,
  FormEvent,
  useState,
  useEffect,
  useContext
} from "react";
import { navigate } from "gatsby";

import { InputField } from "~/components/Inputs";
import Button from "~/components/button";
import { Heading } from "~/components/Typography";
import formValidation from "~/helpers/formValidation";
import { MainContext } from "~/context";

import { Form } from "./form";


interface CreateAccountFormProps {
  secondaryButton?: boolean;
}

interface UserDataTypes {
  email?: string;
  password?: string;
  firstName?: string;
  followUpForm: boolean;
  lastName?: string;
  role?: string;
  schoolDistrict?: string;
  schoolName?: string;
  schoolEmail?: string;
  country?: string;
  state?: string;
}

export const CreateAccountForm = ({ secondaryButton=false }: CreateAccountFormProps) => {
  const { getUser, register } = useContext(MainContext);
  const [errors, setErrors] = useState<UserDataTypes>({});
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState<UserDataTypes>({
    email: "",
    password: "",
    firstName: "",
    followUpForm: true,
    lastName: "",
    role: "",
    schoolDistrict: "",
    schoolEmail: "",
    schoolName: "",
    country: "",
    state: "",
  });

  const emailValidation = formValidation("email");
  const passwordValidation = formValidation("password");

  const emailResult = emailValidation(values.email);
  const passwordResult = passwordValidation(values.password);

  useEffect(() => {
    if (!passwordResult) {
      setErrors((prevState) => {
        return { ...prevState, password: "" };
      });
    }

    if (!emailResult) {
      setErrors((prevState) => {
        return { ...prevState, email: "" };
      });
    }
  }, [values?.password, values?.email]);



  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    return setValues({ ...values, [name]: value });
  };

  const handleValidate = () => {
    if (emailResult || passwordResult) {
      setErrors({
        email: emailResult,
        password: passwordResult,
      });

      return false;
    }

    setErrors({});
    return true;
 };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validateTest = handleValidate();

    if (!validateTest) {
      return window && window.scrollTo({ top: 0, behavior: "smooth" });
    }
    setLoading(true);

    try {
      await register(values);
      await getUser();
      setLoading(false);
      navigate('/dashboard');
    } catch (error) {
      setErrors({
        email: "The user with this e-mail address already exists"
      });

      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });

      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <InputField
        className="mt-4"
        error={errors?.email}
        label="E-mail Address"
        name="email"
        onChange={handleChange}
        value={values.email}
      >
        <div className="text-tn text-blue max-w-sm">
          A valid email address. All emails from the system will be sent to this
          address. The email address is not made public and will only be used if
          you wish to receieve a new password.
        </div>
      </InputField>
      <InputField
        className="mt-6"
        error={errors?.password}
        label="Password"
        name="password"
        onChange={handleChange}
        type="password"
        value={values.password}
      />
      <div className="text-tn text-blue max-w-sm">
        Your password must have at least 8 characters, minimum one lowercase and
        uppercase letter, number and special character
      </div>
      <Heading level={2}>Teacher information</Heading>
      <InputField
        className="mt-4"
        error={errors?.firstName}
        label="First Name"
        name="firstName"
        onChange={handleChange}
        value={values.firstName}
      />
      <InputField
        className="mt-6"
        error={errors?.lastName}
        label="Last Name"
        name="lastName"
        onChange={handleChange}
        value={values.lastName}
      />
      <InputField
        className="mt-6"
        error={errors?.role}
        label="Professional Role"
        name="role"
        onChange={handleChange}
        value={values.role}
      />
      <InputField
        className="mt-6"
        error={errors?.schoolDistrict}
        label="School District"
        name="schoolDistrict"
        onChange={handleChange}
        value={values.schoolDistrict}
      />
      <InputField
        className="mt-6"
        error={errors?.schoolEmail}
        label="School e-mail"
        name="schoolEmail"
        onChange={handleChange}
        value={values.schoolEmail}
      />
      <InputField
        className="mt-6"
        error={errors?.schoolName}
        label="School Name"
        name="schoolName"
        onChange={handleChange}
        value={values.schoolName}
      />
      <InputField
        className="mt-6"
        error={errors?.country}
        label="Country"
        name="country"
        onChange={handleChange}
        value={values.country}
      />
      <InputField
        className="mt-6"
        error={errors?.state}
        label="State/Territory"
        name="state"
        onChange={handleChange}
        value={values.state}
      />
      <div className="mt-6">
        <input
          checked={values.followUpForm}
          className="outline-none focus:ring-4 focus:ring-indigo-300 rounded"
          id="followUpForm"
          name="followUpForm"
          onChange={(e: ChangeEvent<HTMLInputElement>) => setValues({ ...values, followUpForm: e.target.checked })}
          type="checkbox"
        />
        <label htmlFor="followUpForm" className="ml-2 text-gray-600 text-sm">BSCS may contact me with an optional survey about my experience.</label>
      </div>
      <Button
        action={secondaryButton ? "secondary" : "primary"}
        className="mt-6"
        color="purple"
        loading={loading}
        style={{ minWidth: "180px" }}
        title="Sign up"
        type="submit"
      >
        Sign up
      </Button>
    </Form>
  );
};

export default CreateAccountForm;
