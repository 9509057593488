import React, { ChangeEvent, FormEvent, useState, useEffect } from "react";
import axios from 'axios'

import { InputField } from "~/components/Inputs";
import Button from "~/components/button";
import formValidation, { FormValidator } from "~/helpers/formValidation";

import { Form } from "./form";
import { post } from "~/helpers/api";
import sendformDataToPymail from '../../api/pymail/form'


export const ResetPasswordForm: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string | null>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [show_error_notification, setShowErrorNotification] = useState(false)
  const [errors, setErrors] = useState(false)
  const [show_notification, setShowNotification] = useState(false)
  const [sent, setSent] = useState(false)

  const { sendQuery } = require('../../../api/lib/query');
const { CREATE_TOKEN, UPDATE_WITH_PASSWORD } = require('../../../api/consts/mutations');


  const emailValidator: FormValidator = formValidation("email");



  useEffect(() => {
    if (!emailValidator(email)) return setError("");
  }, [email]);



  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitted(true)
    console.log(setSubmitted, "submit")

    if (emailValidator(email)) return setError(emailValidator(email))

    console.log(email)

    const cancel_token = axios.CancelToken.source()

    const createRandomCode = (count = 6) => {
      const randomChars =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (var i = 0; i < count; i++) {
        result += randomChars.charAt(
          Math.floor(Math.random() * randomChars.length)
        );
      }
      console.log(result, "first")
      return result;
    };

    const TOKEN_EXPIRATION_DAYS = 30;
    const now = new Date();
    const expirationDate = now.setDate(now.getDate() + TOKEN_EXPIRATION_DAYS);

    const data  = await sendQuery(CREATE_TOKEN, undefined, {
      token: createRandomCode(20),
      expirationDate,
      email: email,
    });
    console.log(data,"data")

    console.log(data.token , email)

    if (errors) {
      return {
        statusCode: 500,
        body: JSON.stringify(errors),
      };
    }
    const handleSuccess = () => {
      setShowNotification(true)
      setLoading(false)
      setSent(true)
      setSubmitted(true)
      console.log("form sent")
    }

    const handleFail = () => {
      setErrors(true)
      setShowErrorNotification(true)
      console.log("handle fail callback")
      setLoading(true);
    }
    console.log(window.location.href, "location")
    try {
      sendformDataToPymail({
        cancel_token: cancel_token,
        data: {
          email: email,
          sendto: email,
          formName: "ResetPasswordform",
          functions: ["sendResetPasswordCode"],
          link: `https://${window.location.hostname}/new-password?token=${data.data.createToken.token}`,
          contact: "webdev@bscs.org"
        },
        successCallback: handleSuccess,
        failCallback: handleFail

      })
      console.log("try")
    } catch (error) {
      setSubmitted(true);
      setLoading(false);
      console.log("catch")
      console.log(error)
    };
    console.log("skipped")
    // try {
    //   await post("/reset-password", { email });
    //   setLoading(false);
    //   setSubmitted(true);
    // } catch (error) {
    //   setSubmitted(true);
    //   setLoading(false);
    // };
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmail(value);
  };

  return (
    <>
      <Form onSubmit={handleSubmit} data-netlify="true">
        <InputField
          disabled={submitted}
          error={error ? error : undefined}
          label="E-mail Address"
          name="email"
          onChange={handleChange}
          value={email}
        />
        <Button
          action="primary"
          className="mt-5"
          color="purple"
          disabled={submitted}
          loading={loading}
          onClick={handleSubmit}
          style={{ minWidth: "250px" }}
          type="submit"
        >
          Reset password
        </Button>
      </Form>
      {submitted && <p className="mt-5 text-gray-600">Check your email to finish resetting your password.</p>}
    </>
  );
};

export default ResetPasswordForm;