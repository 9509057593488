export type FormValidator = (value: string | undefined, valueToCompare?: string | undefined) => string | null;

const formValidation = (
  type: 'email' | 'password' | 'length' | 'exist' | 'match'
):  FormValidator => {
  return (
    value: string | undefined,
    valueToCompare: string | undefined = ''
  ) => {
    switch (type) {
      case 'email': {
        if (!value || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value))
          return 'E-mail has a wrong format';

        return null;
      }
      case 'password': {
        if (
          !value ||
          !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#^()!%*?&])[A-Za-z\d@#^()$!%*?&]{8,}$/i.test(
            value
          )
        )
          return 'Password has a wrong format';

        return null;
      }
      case 'length': {
        if (!value) {
          return 'Wrong value';
        } else if (value && value.length > 20) {
          return 'Value too long';
        }
        return null;
      }
      case 'exist': {
        if (!value) {
          return 'Wrong value';
        }

        return null;
      }
      case 'match': {
        if (
          !value ||
          !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/i.test(
            value
          )
        ) {
          return 'Password has a wrong format';
        } else if (value !== valueToCompare) {
          return 'Passwords are not equal';
        }
      }
      default: {
        return null;
      }
    }
  };
};

export default formValidation;
