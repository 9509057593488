import React, { ChangeEvent, CSSProperties, ReactNode } from "react";
import clsx from "clsx"

import { formatClassList, joinStrings } from "~/utils";

interface InputProps {
  children?: ReactNode;
  className?: string;
  color?: string;
  disabled?: boolean;
  error?: string | boolean;
  id?: string;
  name?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  style?: CSSProperties;
  type?: string;
  value?: string;
  [rest:string]: unknown;
}

const INPUT: string = `
  bg-white
  border
  border-solid
  disabled:bg-gray-200
  disabled:text-gray-700
  disabled:shadow-none
  disabled:border-none
  duration-300
  focus:bg-white
  focus:ring-2
  focus:ring-gray-300
  font-bold
  leading-6
  outline-none
  placeholder-gray-400
  px-4
  py-3
  rounded-lg
  shadow
  text-base
  text-darkBlue
  transition-colors
  w-full
`

export const InputText = ({
  children,
  placeholder,
  onChange,
  value,
  name,
  type = "text",
  color = "hs",
  error,
  disabled,
  className,
  style,
  ...rest
}: InputProps) => {

  const formattedInput: string = formatClassList(joinStrings(" ", INPUT, className || ""));

  return (
    <input
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      color={color}
      name={name}
      type={type}
      disabled={disabled}
      className={clsx(formattedInput, {
         "border-lightError": error,
         "border-gray-300": !error
      })}
      style={{caretColor: color === "ms" ? "#6907b0" : "#36469c", ...style}}
      {...rest}
    >
      {children}
    </input>
  )
};

export default InputText;
