import React, { MouseEvent, useState, useContext } from "react";
import { navigate } from "gatsby";
import { useFormik } from "formik";

import { InputField } from "~/components/Inputs";
import LabeledRadio from "~/components/Radios/LabeledRadio";
import Button from "~/components/button";
import { useLesson } from "~/hooks/useLesson";
import { post } from "~/helpers/api";
import { MainContext } from "~/context";

import { createRandomCode } from "~/helpers/createRandomCode";

const RADIO_BUTTONS = {
  name: "radio",
  items: [
    {
      id: "1",
      value: "HS",
      label: "High school",
    },
    {
      id: "2",
      value: "MS",
      label: "Middle school",
    },
  ],
};

export const AddClassForm = () => {
  const { color } = useLesson();
  const [loading, setLoading] = useState(false);

  const { getUser } = useContext(MainContext);

  const handleSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setLoading(true)

    try {
      await post("/createClass", {
        name: formik.values.header,
        code: formik.values.code,
        level: formik.values.radio,
      })
      await getUser()
      setLoading(false)
      navigate("/dashboard");
    } catch(error) {
      setLoading(false)
    }
  };

  const formik = useFormik({
    initialValues: {
      header: "",
      code: createRandomCode(6),
      radio: "",
    },
  });

  const handleChange = (event: { target: HTMLInputElement }) => {
    const { name, value } = event.target;
    formik.setFieldValue(name, value);
  };

  return (
    <form className="flex flex-col w-4/6">
      <div className={`flex flex-wrap lg:flex-nowrap`}>
        <InputField
          className="min-w-sm"
          onChange={handleChange}
          label="Name of class"
          value={formik.values.header}
          name="header"
          error={formik.errors.header}
        />
        <InputField
          className="lg:ml-3"
          onChange={handleChange}
          label="Class code"
          value={formik.values.code}
          name="code"
          error={formik.errors.code}
          style={{ maxWidth: "180px"}}
          disabled
        />
      </div>
      <p className="mt-8 text-gray-600 font-medium">Please select the appropriate grade level for this class.</p>
      <div className="mt-3">
        {/* TODO: Tab navigation is NOT working on the unselected radio option. I'm not sure why. */}
        <LabeledRadio
          defaultChecked={formik.values.radio}
          color={color}
          label="Class level"
          radios={RADIO_BUTTONS}
          onChange={handleChange}
        />
      </div>
      <Button
        action="primary"
        className="mt-8"
        color="purple"
        disabled={Object.values(formik.values).some((prop) => prop === "")}
        loading={loading}
        onClick={handleSubmit}
        style={{ minWidth: "152px" }}
        title="Add"
        type="submit"
      >
        Add
      </Button>
    </form>
  );
};

export default AddClassForm;
