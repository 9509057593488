import React, { ReactNode } from "react";

interface FormProps {
  children: ReactNode;
  [rest:string]: unknown;
}

export const Form = ({children, ...rest}: FormProps) => (
  <form {...rest}>{children}</form>
)
