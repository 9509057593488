import React, { ReactNode } from 'react';
import clsx from 'clsx';

import InputText from '~/components/Inputs/InputText';


interface InputProps {
  children?: ReactNode,
  className?: string;
  color?: string;
  disabled?: boolean;
  error?: boolean | string;
  label?: string;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: string;
  value?: string;
}

export const InputField = ({
  children,
  className,
  disabled,
  error,
  label,
  name,
  onChange,
  placeholder,
  type="text",
  value,
  ...rest
}: InputProps) => (
  <div className={className}>
    <label className={clsx("text-sm relative w-full max-w-md grid flex-col items-start gap-3", {
     "text-lightError": !!error,
     "text-gray-600": !error
    })}>
      {error || label}
      <InputText
        {...rest}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        type={type}
        error={error}
        disabled={disabled}
      />
      {children}
    </label>
  </div>
);
